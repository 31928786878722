import { breakpoints } from '../tokens';
import { useMediaQuery } from '../use-media-query';

export const useIsTablet = () =>
  useMediaQuery(`(min-width: ${breakpoints.md})`);

export const useIsLaptop = () =>
  useMediaQuery(`(min-width: ${breakpoints.lg})`);

export const useIsDesktop = () =>
  useMediaQuery(`(min-width: ${breakpoints.xl})`);

export const useIsWide = () =>
  useMediaQuery(`(min-width: ${breakpoints['2xl']})`);

export const useIsUltraWide = () =>
  useMediaQuery(`(min-width: ${breakpoints['3xl']})`);
