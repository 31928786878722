import { useCallback, useSyncExternalStore } from 'react';

const getSnapshot = (query: string) => {
  return window.matchMedia(query).matches;
};

const subscribe = (onChange: () => void, query: string) => {
  const mql = window.matchMedia(query);
  mql.addEventListener('change', onChange);

  return () => {
    mql.removeEventListener('change', onChange);
  };
};

export const useMediaQuery = (query: string) => {
  const subscribeMediaQuery = useCallback(
    (onChange: () => void) => {
      return subscribe(onChange, query);
    },
    [query],
  );

  const matches = useSyncExternalStore(
    subscribeMediaQuery,
    () => getSnapshot(query),
    () => false,
  );

  return matches;
};
